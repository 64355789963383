import Link from "next/link";
import type { NextPage } from "next";
import { useTranslation } from "next-i18next";
import { NextSeo } from "next-seo";
import Head from "next/head";
import { useEffect, useMemo, useState } from "react";
import { useRecoilState } from "recoil";
import { authFormState } from "../Atoms/auth/AuthFormState";
import AuthCard from "../components/auth/AuthCard";
import { API_URL, AppConfig } from "../config";
import { useToast } from "@/components/ui/use-toast";
import { getSession } from "next-auth/react";
import axios from "axios";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { useDarkTheme } from "@/contexts/DarkTheme";
import { cn } from "@/lib/utils";
import FavIconsMeta from "@/components/FavIconsMeta";
import GoogleAnalytics from "@/components/GA";
import { useRouter } from "next/router";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { BsGlobe } from "react-icons/bs";
import { Label } from "@/components/ui/label";
import { Switch } from "@/components/ui/switch";
import { AspectRatio } from "@/components/ui/aspect-ratio";
import { LOGOS } from "@/config/logos";

const Home: NextPage = (props: any) => {
  const router = useRouter();
  const { toast } = useToast();
  const [showLanguageMenu, setShowLanguageMenu] = useState<boolean>(false);
  const [enabledSwitch, setEnabledSwitch] = useState(
    router.query.locale || "en"
  );
  const [formState, setFormState] = useRecoilState(authFormState);
  const { darkTheme } = useDarkTheme();
  const { t } = useTranslation("common");
  const { t: ct } = useTranslation("layout");

  const selectedLanguage = useMemo(
    () => router.query.locale || enabledSwitch,
    [router.query.locale, enabledSwitch]
  );

  const languagesOptions = useMemo(() => {
    return [
      {
        name: ct("header.chinese"),
        value: "zh",
      },
      {
        name: ct("header.english"),
        value: "en",
      },
      {
        name: ct("header.german"),
        value: "de",
      },
      {
        name: ct("header.italian"),
        value: "it",
      },
      {
        name: ct("header.portuguese"),
        value: "pt",
      },
      {
        name: ct("header.spanish"),
        value: "es",
      },
    ];
  }, [t]);

  useEffect(() => {
    if (props?.user !== null) {
      if (localStorage && props?.user?.user) {
        localStorage.setItem("user-info", JSON.stringify(props?.user));
        localStorage.setItem("userAuthenticated", "true");
        if (
          props?.user.subscription &&
          props?.user.subscription == "inactive"
        ) {
          router.push(`/subscription?locale=${router.query.locale || "en"}`);
        }
        router.push(`/build?locale=${router.query.locale || "en"}`);
      }
    }
  }, []);

  const toastMessage = (status: boolean, message: string) => {
    if (status == true) {
      toast({
        title: message,
      });
      return;
    } else {
      toast({
        title: message,
        variant: "destructive",
      });
    }
    // throw new Error(message);
  };

  return (
    <>
      <Head>
        {process.env.NODE_ENV === "production" ? (
          <meta name="robots" content="index,follow" />
        ) : (
          <meta name="robots" content="noindex,nofollow" />
        )}
        <GoogleAnalytics GA_TRACKING_ID={"G-NRPKERR6KT"} />
        <meta charSet="UTF-8" key="charset" />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
          key="viewport"
        />
        <FavIconsMeta />
        <link
          rel="stylesheet"
          href="https://rsms.me/inter/inter.css"
          key="inter-font"
        />
      </Head>
      <NextSeo
        title={AppConfig.title}
        description={AppConfig.description}
        openGraph={{
          locale: AppConfig.locale,
          site_name: AppConfig.site_name,
        }}
      />
      <div className="relative ">
        <div
          className="flex absolute z-10 right-1 top-1 justify-center items-center"
          id="languageMenu"
        >
          <DropdownMenu
            open={showLanguageMenu}
            onOpenChange={(open) => setShowLanguageMenu(open)}
          >
            <DropdownMenuTrigger asChild>
              <div className="flex flex-col">
                <BsGlobe
                  className="cursor-pointer"
                  size={12}
                  onClick={() => {
                    setShowLanguageMenu(true);
                  }}
                />
              </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-52" sideOffset={20}>
              <DropdownMenuLabel>
                {ct("header.PlatformLanguage")}
              </DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuGroup>
                <div className="flex flex-col gap-2 px-2">
                  {languagesOptions.map((lang, index) => {
                    const isSelected = lang.value === selectedLanguage;
                    return (
                      <div
                        className="flex flex-row items-center justify-between gap-2"
                        key={index}
                      >
                        <Label htmlFor={"lang-box-" + lang.value}>
                          {lang.name}
                        </Label>
                        <Switch
                          id={"lang-box-" + lang.value}
                          checked={isSelected}
                          disabled={isSelected}
                          onCheckedChange={() => {
                            setEnabledSwitch(lang?.value || "en");
                            router.query.locale = lang?.value || "en";
                            router.push(router);
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </DropdownMenuGroup>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
        <div className="container relative md:h-screen pt-[5rem] md:pt-[0px] flex flex-col-reverse items-center justify-center md:grid md:max-w-none md:grid-cols-2 md:px-0 ">
          <div className="relative flex flex-col h-full bg-muted p-10">
            <div className="absolute inset-0 bg-tl2-blue-extradark" />
            <div className="hidden md:flex w-full justify-between z-20 items-center text-lg font-medium">
              <Link href="https://trylevel2.com/">
                <img
                  src={LOGOS.BRAND.DARK.small}
                  className="md:max-h-[60px] xs:max-h-[25px] max-h-[25px] sm:max-w-[300px]"
                  alt=""
                />
              </Link>
              {/* Plus100 */}
            </div>
            {/* <div className="relative flex flex-col z-20 text-white m-auto gap-12 items-center justify-center">
              <div className="items-center mx-auto w-full max-w-2xl font-semibold text-white text-center text-xl">
                {
                  "Share strategies and make collaborative decisions, leveraging the intelligence of a global community of active traders"
                }
              </div>
              <AspectRatio
                ratio={16 / 9}
                className="h-96 flex w-full max-w-xl mx-auto"
              >
                <iframe
                  src="https://www.youtube.com/embed/u1u61xGEl0c"
                  title="Introducing Plus100 - Smarter Trading for the Active Trader"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  //@ts-ignore
                  webkitallowfullscreen="true"
                  mozallowfullscreen="true"
                  allowFullScreen
                  className="flex grow w-full"
                ></iframe>
              </AspectRatio>
            </div> */}
          </div>
          <div className="lg:p-8">
            <div
              className={cn(
                "mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]",
                formState.view == "create" ? "sm:w-[650px]" : ""
              )}
            >
              <Link href="https://trylevel2.com/">
                <Avatar className="rounded-none mx-auto w-fit h-fit">
                  <AvatarImage
                    src={
                      darkTheme
                        ? LOGOS.BRAND.DARK.small
                        : LOGOS.BRAND.LIGHT.small
                    }
                    className="aspect-auto max-h-10 sm:max-w-[300px] flex md:hidden object-contain"
                    alt=""
                  />
                  <AvatarFallback className="text-3xl font-bold bg-transparent">
                    Plus100
                  </AvatarFallback>
                </Avatar>
              </Link>
              <div className="flex flex-col  space-y-2 text-center">
                <h1 className="text-2xl font-semibold tracking-tight">
                  {formState.view == "login"
                    ? t("Sign in to your account")
                    : formState.view == "resetPassword"
                    ? t("Reset your password")
                    : formState.view == "create"
                    ? t("Create an account")
                    : t("Verify your login attempt")}
                </h1>
              </div>
              <AuthCard toast={toastMessage} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export const getServerSideProps = async (ctx: any) => {
  let session: any = await getSession(ctx);
  let res = null;
  if (session) {
    let url = new URL("social_login", API_URL);
    url.searchParams.append("token", session.token.access_token);

    res = await axios
      .get(url.toString())
      .catch((e) => {
        console.error(e);
        return { data: {} };
      })
      .finally(() => ({ data: {} }));
  }
  return {
    props: {
      user: res?.data || null,
      ...(await serverSideTranslations(ctx.locale || "en", [
        "common",
        "layout",
      ])),
    },
  };
};

export default Home;
