import { useSession } from "next-auth/react";
import { useRouter } from "next/router";
import { useLayoutEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSetRecoilState } from "recoil";
import { authFormState } from "../../Atoms/auth/AuthFormState";
import { API_URL } from "../../config";
import { useToast } from "@/components/ui/use-toast";
import { Eye, EyeOff, Loader2 } from "lucide-react";
import { NextPage } from "next";
import { signOut } from "next-auth/react";
import { useTranslation } from "next-i18next";

import Disclaimer from "../Desclaimer";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import GoogleAnalytics from "../GA";
import { useSubscription } from "@/contexts/SubscriptionContext";
import { usePatternUniverses } from "@/contexts/PatternUniverse";
import { useUserContext } from "@/contexts/userContext";
import { usePostLogin, usePostVerify } from "@/api/userApi";
import Loader from "../Loader";
interface IFormValues {
  email: string;
  password: string;
}

const LoginCard: NextPage = (props: any) => {
  const { toast } = useToast();
  const router = useRouter();
  const { t } = useTranslation("common");
  const setFormState = useSetRecoilState(authFormState);
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const { setSubscriptionData, setMetaData, getUserType } = useSubscription();
  const { refetchScannerStrategies, refetchUniverse } = usePatternUniverses();
  const { setUser } = useUserContext();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormValues>();
  const { mutateAsync: login, isPending: loading } = usePostLogin();
  const { mutateAsync: verify, isPending: sessionLoading } = usePostVerify();

  const togglePasswordVisibility = () => {
    if (showPassword) {
      setShowPassword(false);
    } else {
      setShowPassword(true);
    }
  };

  const onSubmit = async (formData: any) => {
    try {
      const formD = new FormData();
      formD.append("email", (formData.email as string) || "");
      formD.append("password", (formData.password as string) || "");

      const res = await login({ formData: formD });

      if (res.status !== 200) throw new Error("Login failed");

      setSubscriptionData(JSON.parse(res?.data?.subscription || "null"));
      setMetaData(res?.data?.user?.meta_data || null);
      getUserType(res.data);
      setUser(res.data);
      localStorage.setItem("user-info", JSON.stringify(res.data));
      localStorage.setItem("userAuthenticated", "true");
      refetchScannerStrategies(true, res?.data?.user_id);
      refetchUniverse(true, res?.data?.user_id);

      router.query.locale = res.data.preference.language || "en";

      if (res.data?.subscription === "inactive") {
        return router.push(
          `/subscription?locale=${router.query.locale || "en"}`
        );
      }

      if (router.query.redirect) {
        return router.push(router.query.redirect as string);
      }

      return router.push(`/welcome-tour?locale=${router.query.locale || "en"}`);
    } catch (e: any) {
      const errorMsg =
        e?.response?.data?.detail ||
        e?.response?.data?.error ||
        "Incorrect email or password";
      setError(errorMsg);
      toast({ title: errorMsg, variant: "destructive" });
    }
  };

  const verifySession = async () => {
    try {
      const userInfo = JSON.parse(localStorage.getItem("user-info")!);
      const userAuthenticated = localStorage.getItem("userAuthenticated");
      console.log("api triggered");

      if (userInfo?.user_id && userAuthenticated === "true") {
        const res = await verify();

        if (res.statusText === "OK") {
          router.query.locale = userInfo?.preference.language || "en";

          if (router.query.redirect) {
            await router.push(router?.query?.redirect?.toString());
            return;
          }

          await router.push(
            `/welcome-tour?locale=${
              userInfo?.language || router.query.locale || "en"
            }`
          );
        }
      }
    } catch (e) {
      console.log("api error");

      localStorage.clear();
      signOut({ redirect: false });
    }
  };

  useLayoutEffect(() => {
    console.log("triggered");
    verifySession();
  }, []);

  if (sessionLoading)
    return (
      <div className="fixed h-screen w-screen bg-[hsl(var(--background))] z-50 top-0 left-0 flex justify-center items-center">
        <Loader />
      </div>
    );
  return (
    <>
      <GoogleAnalytics GA_TRACKING_ID={"G-NRPKERR6KT"} />
      <div className="max-w-[500px] mb-5">
        <form
          method="post"
          autoComplete="false"
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col space-y-4  loginForm"
        >
          <Input
            {...register("email", {
              required: true,
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: t("components.loginCard.error.validEmail"),
              },
            })}
            type="text"
            autoComplete="off"
            onKeyUp={() => setError("")}
            placeholder={t("components.loginCard.emailAddress")}
            name="email"
          />
          {errors?.email && (
            <div className="danger text-left">
              {errors.email?.type === "required" && (
                <p className="text-destructive text-sm">
                  {t("components.loginCard.error.emailRequired")}
                </p>
              )}
              {errors.email?.type === "pattern" && (
                <p className="text-destructive text-sm">
                  {errors.email?.message}
                </p>
              )}
            </div>
          )}
          <div className="relative">
            <Input
              {...register("password", { required: true })}
              type={showPassword ? "text" : "password"}
              autoComplete="off"
              placeholder={t("components.loginCard.password")}
              onKeyUp={() => setError("")}
              name="password"
            />
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400 cursor-pointer">
              {showPassword ? (
                <EyeOff
                  className="h-5 w-5"
                  onClick={togglePasswordVisibility}
                />
              ) : (
                <Eye className="h-5 w-5" onClick={togglePasswordVisibility} />
              )}
            </div>
          </div>

          {errors?.password && (
            <div className="danger text-left">
              {errors.password?.type === "required" && (
                <p className="text-destructive text-sm">
                  {t("components.loginCard.error.passwordRequired")}
                </p>
              )}
            </div>
          )}
          {error && <span className="text-destructive text-sm">{error}</span>}

          <div className="text-center">
            <Button type="submit">
              {t("components.loginCard.signIn")}

              {loading ? <Loader2 className="ml-2 h-5 w-5 animate-spin" /> : ""}
            </Button>
          </div>
        </form>
      </div>
      <div className="flex flex-col !mt-2">
        <div className="flex flex-row justify-center items-center text-newGreen  text-xs"></div>
      </div>
      <div className="space-y-4">
        <div className="text-center text-sm text-muted-foreground">
          <div className="text-sm text-muted-foreground">
            {t("components.loginCard.resetPassword")}{" "}
            <span
              className="cursor-pointer text-sm text-muted-foreground underline"
              onClick={() => setFormState({ view: "resetPassword" })}
            >
              {t("components.loginCard.here")}
            </span>
          </div>
          <div>
            {t("Do not have account? Create ")}{" "}
            <a
              href={`/create-new-account?locale=${router.query.locale || "en"}`}
              className="underline"
              target="_blank"
            >
              {t("components.loginCard.here")}
            </a>
          </div>
          <div className="text-sm">
            {t("components.loginCard.riskTrading")}{" "}
            <span className="cursor-pointer text-xs text-muted-foreground underline">
              <Disclaimer />
            </span>
          </div>
        </div>
        <hr className="text-muted-foreground" />
        <div className="text-center text-sm text-muted-foreground">
          <span>{t("components.troubleLogging")}</span>
          <Button
            variant="link"
            className="p-0 h-auto text-muted-foreground hover:text-foreground underline"
            onClick={() => {
              window.location.href =
                "mailto:contact@trylevel2.com?subject=Request%20Assistance&body=Hi%20Team,%0DI%20need%20assistance.%20My%20contact%20email%20is%20{enter%20your%20email%20address}%0D%0D%0D%0D";
            }}
          >
            {t("components.contactMail")}
          </Button>
        </div>
      </div>
    </>
  );
};

export default LoginCard;
